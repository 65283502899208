var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "email-app-details"
  }, [_c('div', {
    staticClass: "email-detail-header"
  }, [_c('div', {
    staticClass: "email-header-left d-flex align-items-center"
  }, [_c('span', {
    staticClass: "go-back mr-1"
  }, [_c('feather-icon', {
    staticClass: "align-bottom",
    attrs: {
      "icon": _vm.$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon',
      "size": "20"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-email-view');
      }
    }
  })], 1), _c('h4', {
    staticClass: "email-subject mb-0"
  }, [_vm._v(" " + _vm._s(_vm.emailViewData.subject) + " ")])]), _c('div', {
    staticClass: "email-header-right ml-2 pl-1"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    class: {
      'text-warning fill-current': _vm.emailViewData.isStarred
    },
    attrs: {
      "icon": "StarIcon",
      "size": "17"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggle-email-starred');
      }
    }
  }), _c('b-dropdown', {
    staticClass: "ml-75",
    attrs: {
      "variant": "link",
      "no-caret": "",
      "toggle-class": "p-0",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "FolderIcon",
            "size": "17"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('move-email-to-folder', 'draft');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit2Icon"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Draft")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('move-email-to-folder', 'spam');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Spam")])], 1), _c('b-dropdown-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.params.folder !== 'trash',
      expression: "$route.params.folder !== 'trash'"
    }],
    on: {
      "click": function click($event) {
        return _vm.$emit('move-email-to-folder', 'trash');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TrashIcon"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Trash")])], 1)], 1), _c('b-dropdown', {
    staticClass: "ml-75",
    attrs: {
      "variant": "link",
      "no-caret": "",
      "toggle-class": "p-0",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "TagIcon",
            "size": "17"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update-email-label', 'personal');
      }
    }
  }, [_c('span', {
    staticClass: "mr-50 bullet bullet-success bullet-sm"
  }), _c('span', [_vm._v("Personal")])]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update-email-label', 'company');
      }
    }
  }, [_c('span', {
    staticClass: "mr-50 bullet bullet-primary bullet-sm"
  }), _c('span', [_vm._v("Company")])]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update-email-label', 'important');
      }
    }
  }, [_c('span', {
    staticClass: "mr-50 bullet bullet-warning bullet-sm"
  }), _c('span', [_vm._v("Important")])]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update-email-label', 'private');
      }
    }
  }, [_c('span', {
    staticClass: "mr-50 bullet bullet-danger bullet-sm"
  }), _c('span', [_vm._v("Private")])])], 1), _c('feather-icon', {
    staticClass: "ml-75 cursor-pointer",
    attrs: {
      "icon": "MailIcon",
      "size": "17"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('mark-email-unread');
      }
    }
  }), _c('feather-icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.params.folder !== 'trash',
      expression: "$route.params.folder !== 'trash'"
    }],
    staticClass: "ml-75",
    attrs: {
      "icon": "TrashIcon",
      "size": "17"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('move-email-to-folder', 'trash');
      }
    }
  }), _c('feather-icon', {
    staticClass: "ml-75 cursor-pointer",
    class: {
      'text-muted pointer-events-none': !_vm.opendedEmailMeta.hasPreviousEmail
    },
    attrs: {
      "icon": _vm.$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon',
      "size": "17"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('change-opened-email', 'previous');
      }
    }
  }), _c('feather-icon', {
    staticClass: "ml-75 cursor-pointer",
    class: {
      'text-muted pointer-events-none': !_vm.opendedEmailMeta.hasNextEmail
    },
    attrs: {
      "icon": _vm.$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon',
      "size": "17"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('change-opened-email', 'next');
      }
    }
  })], 1)]), _c('vue-perfect-scrollbar', {
    staticClass: "email-scroll-area scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "email-label"
  }, _vm._l(_vm.emailViewData.labels, function (label) {
    return _c('b-badge', {
      key: label,
      staticClass: "text-capitalize mr-50",
      attrs: {
        "pill": "",
        "variant": "light-".concat(_vm.resolveLabelColor(label))
      }
    }, [_vm._v(" " + _vm._s(label) + " ")]);
  }), 1)])], 1), !_vm.showWholeThread && _vm.emailViewData.replies && _vm.emailViewData.replies.length ? _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-link', {
    staticClass: "font-weight-bold",
    on: {
      "click": function click($event) {
        _vm.showWholeThread = true;
      }
    }
  }, [_vm._v(" View " + _vm._s(_vm.emailViewData.replies.length) + " Earlier Message" + _vm._s(_vm.emailViewData.replies.length > 1 ? 's' : '') + " ")])], 1)], 1) : _vm._e(), _vm.showWholeThread ? _vm._l(_vm.emailViewData.replies.slice().reverse(), function (threadMail) {
    return _c('b-row', {
      key: threadMail.id
    }, [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('email-message-card', {
      attrs: {
        "message": threadMail
      }
    })], 1)], 1);
  }) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('email-message-card', {
    attrs: {
      "message": _vm.emailViewData
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Click here to "), _c('b-link', [_vm._v("Reply")]), _vm._v(" or "), _c('b-link', [_vm._v("Forward")])], 1)])])], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }